import React from "react";
import './form.css';

const SuccessMessage = ({ insertedId, department, name }) => {
  // Define room numbers for each department
  const departmentToRoom = {
    Gynaecology: 1,
    ENT: 2,
    Orthopaedics: 3,
    Dermatology: 4,
    Ophthalmology: 5,
    General: 6
  };

  // Get the room number based on the department
  const roomNumber = departmentToRoom[department] || "Not Available";

  return (
    <div className="success-message">
      <h4>Registration successful !</h4>
      <p className="success-details">Token No.: {insertedId}</p>
      <p className="success-details">Room No: {roomNumber}</p>
      <p className="success-details">Department: {department}</p>
      <p className="success-details">Name: {name}</p>
    </div>
  );
}

export default SuccessMessage;
