import React from "react";
import { FaTwitter, FaFacebook } from 'react-icons/fa';
import { Badge } from 'react-bootstrap';

const Footer = () => (
  <footer className="bg-dark text-white text-center py-3">
    <div className="container">
      <div className="row">
        <div className="col d-flex justify-content-between align-items-center">
          {/* Left side: "Join your Hand" and "Donate, Help Poor & Save Tax" stacked vertically */}
          <div className="d-flex flex-column">
            <div className="mt-2">Join your Hand</div>
            <div className="mt-2">
              <h5>
                Donate, Help Poor & Save Tax <Badge bg="secondary">under 80G</Badge>
              </h5>
            </div>
          </div>

          {/* Right side: Social media icons */}
          <div>
            <a
              href="https://twitter.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white mx-2"
            >
              <FaTwitter size={24} />
            </a>
            <a
              href="https://facebook.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white mx-2"
            >
              <FaFacebook size={24} />
            </a>
          </div>
        </div>
      </div>

      <div className="row mt-2">
        <div className="col">
          <p>© 2024 Keshav Foundation, Bengaluru. <br />All rights reserved.</p>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
