import { Link } from "react-router-dom"; // Import Link from react-router-dom
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import Container from "react-bootstrap/Container";
import { BiHeart } from "react-icons/bi";
import { BsPeople } from "react-icons/bs";
import { BiImage } from "react-icons/bi";
import background from "../img/fimage1.png";

import "../App.css";

function firstComp() {
  return (
    <Container
      className="d-flex align-items-center justify-content-center"
      style={{
        backgroundImage: `url(${background})`,
        backgroundRepeat: "no-repeat",
      }}
      fluid
    >
      <Card className="transparent-card" border="0">
        <Card.Title className="p-4">
          <Card className="transparent-card" border="0">
            <Card.Title className="p-4" style={{ color: "#FFF" }}>
              HELP for Health, rural YOUTHS, poor STUDENTS, poor BRIDES &
              COMMUNITIES.
            </Card.Title>
          </Card>
        </Card.Title>
        <Card.Body className="d-flex align-items-center justify-content-center shadow-lg p-0 mb-5 bg-red rounded">
          {/* <Card className="text-center" border="0"> */}
          <Card
            className="text-center border-0 p-0 w-75"
            style={{ fontSize: "1.25rem" }}
          >
            <Card.Body border="0">
              <Card.Text className="p-2">
                <ListGroup className="d-flex flex-column flex-md-row justify-content-center">
                  {/* Button for Public Event */}
                  <ListGroup.Item className="w-100 w-md-auto mb-2 mb-md-0">
                    <Link to="/event" className="w-100">
                      <Button variant="primary" className="d-block w-100">
                        <BsPeople size={30} />
                        Health Camps
                      </Button>
                    </Link>
                  </ListGroup.Item>
                  {/* Button for Photo Gallery */}
                  <ListGroup.Item className="w-100 w-md-auto">
                    <Link to="/comingSoon" className="d-block w-100">
                      <Button variant="primary" className="d-block w-100">
                        <BiImage size={30} /> 
                        Skills Camps
                      </Button>
                    </Link>
                  </ListGroup.Item>
                  {/* Button for Sponsoring */}
                  <ListGroup.Item className="w-100 w-md-auto mb-2 mb-md-0">
                    <Link to="/comingSoon" className="d-block w-100">
                      <Button variant="primary" className="d-block w-100">
                      <BiHeart size={30} color="red" />
                      Matrimony
                      </Button>
                    </Link>
                  </ListGroup.Item>
                </ListGroup>
              </Card.Text>
            </Card.Body>
          </Card>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default firstComp;
