import "./form.css";
import React, { useState } from "react";
import axios from "axios";
import SuccessMessage from "./SuccessMessage";
import ErrorMessage from "./ErrorMessage";
import { Button } from "react-bootstrap";

const RegisterForm = ({ onFormSubmit }) => {
  const [formData, setFormData] = useState({
    name: "",
    city: "Ankola", // Set default value to "AAA"
    mobile: "",
    age: "",
    weight: "",
    height: "",
    message: "",
    department: "",
    hasBP: false, // State for BP checkbox
    hasSugar: false, // State for Sugar checkbox
  });

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [responseData, setResponseData] = useState(null); // State to store the response data

  // Handle form data changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle checkbox changes
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormData({ ...formData, [name]: checked });
  };

  // Ensure that only numeric input is allowed for the mobile number
  const handleMobileInput = (event) => {
    const value = event.target.value;
    if (/^[0-9]{0,10}$/.test(value)) {
      // Allow only digits, and limit to 10 characters
      setFormData({ ...formData, mobile: value });
    }
  };

  // Validate mobile number (must be exactly 10 digits)
  const isMobileValid = (mobile) => {
    const mobileRegex = /^[0-9]{10}$/;
    return mobileRegex.test(mobile);
  };

  // Handle form submission
// Handle form submission
const handleSubmit = async (event) => {
  event.preventDefault();

  // Validate form data
  if (
    !formData.name ||
    !formData.city ||
    !formData.mobile ||
    !formData.age ||
    !formData.weight ||
    !formData.height ||
    !formData.message ||
    !formData.department // Validate department
  ) {
    setErrorMessage("Please fill in all the fields.");
    return;
  }

  // Validate mobile number
  if (!isMobileValid(formData.mobile)) {
    setErrorMessage("Please enter a valid 10-digit mobile number.");
    return;
  }

  try {
    const response = await axios.post(
      "https://helpin.in/kf-form/submit.php",
      formData
    );

    // Log the response to inspect
    console.log("Response from server:", response.data);

    if (response.data.success) {
      // Extract insertedId and department from the response
      const { inserted_id, department, name } = response.data;

      // Log values for verification
      console.log("Inserted ID:", inserted_id);
      console.log("Department:", department);

      // Store the response data in the state
      setResponseData({ insertedId: inserted_id, department, name });

      // Reset form data after successful submission
      setFormData({
        name: "",
        city: "AAA", // Reset city to default value after submission
        mobile: "",
        age: "",
        weight: "",
        height: "",
        message: "",
        department: "", // Reset department after submission
        hasBP: false, // Reset BP checkbox
        hasSugar: false, // Reset Sugar checkbox
      });

      setFormSubmitted(true); // Set submission status to true
      setErrorMessage(""); // Clear error message if submission is successful

      // Scroll to top after form submission
      window.scrollTo(0, 0);
    } else {
      setErrorMessage("Error: Form submission failed.");
    }
  } catch (error) {
    console.error("Error submitting form:", error);
    setErrorMessage("There was an error submitting your data.");
  }
};


  return (
    <>
      <br />
      <div className="form-container">
        {formSubmitted && responseData && (
          <SuccessMessage
            insertedId={responseData.insertedId}
            department={responseData.department}
            name={responseData.name}
          />
        )}
        {errorMessage && <ErrorMessage message={errorMessage} />}
        <br />
        <h4>NEW FORM</h4>
        <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <label htmlFor="city">City:</label>
            <input
              type="text"
              name="city"
              value={formData.city}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <label htmlFor="mobile">Mobile:</label>
            <input
              type="text"
              name="mobile"
              value={formData.mobile}
              onChange={handleMobileInput} // Call handleMobileInput to restrict non-numeric input
            />
          </div>
          <div>
            <label htmlFor="age">Age:</label>
            <input
              type="number"
              name="age"
              value={formData.age}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <label htmlFor="weight">Weight (kg):</label>
            <input
              type="number"
              name="weight"
              value={formData.weight}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <label htmlFor="height">Height (cm):</label>
            <input
              type="number"
              name="height"
              value={formData.height}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <label htmlFor="department">Select Dept:</label>
            <select
              name="department"
              value={formData.department}
              onChange={handleInputChange}
            >
              <option value="">--Select Department--</option>
              <option value="Gynaecology">Gynaecology</option>
              <option value="ENT">ENT</option>
              <option value="Orthopaedics">Orthopaedics</option>
              <option value="Dermatology">Dermatology</option>
              <option value="Ophthalmology">Ophthalmology</option>
              <option value="General">General/Community medicine</option>
            </select>
          </div>

          {/* Add BP and Sugar checkboxes */ }
          <div className="checkbox-container">
  <div className="checkbox-item">
    <label htmlFor="hasBP">
      <input
        type="checkbox"
        name="hasBP"
        checked={formData.hasBP}
        onChange={handleCheckboxChange}
      />
      BP (Blood Pressure)
    </label>
  </div>
  <div className="checkbox-item">
    <label htmlFor="hasSugar">
      <input
        type="checkbox"
        name="hasSugar"
        checked={formData.hasSugar}
        onChange={handleCheckboxChange}
      />
      Sugar (Blood Sugar)
    </label>
  </div>
</div>

          
          <div>
            <label htmlFor="message">Issue:</label>
            <textarea
              name="message"
              value={formData.message}
              onChange={handleInputChange}
            />
          </div>

          {/* Add BP and Sugar checkboxes */}
          {/* <div>
            <label htmlFor="hasBP">
              <input
                type="checkbox"
                name="hasBP"
                checked={formData.hasBP}
                onChange={handleCheckboxChange}
              />
              BP (Blood Pressure)
            </label>
          </div> */}
          {/* <div>
            <label htmlFor="hasSugar">
              <input
                type="checkbox"
                name="hasSugar"
                checked={formData.hasSugar}
                onChange={handleCheckboxChange}
              />
              Sugar (Blood Sugar)
            </label>
          </div> */}


          <Button
            variant="primary"
            size="lg"
            type="submit"
            className="btn btn-success"
          >
            Submit
          </Button>
        </form>
      </div>
    </>
  );
};

export default RegisterForm;
