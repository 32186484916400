import React, { useState, useEffect } from "react";

const PatientList = () => {
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [patients, setPatients] = useState([]);

  // Fetch patients based on department selection
  useEffect(() => {
    if (selectedDepartment) {
      fetch(
        `https://helpin.in/kf-form/PatientDetails.php?department=${selectedDepartment}`
      )
        .then((response) => response.json())
        .then((data) => setPatients(data))
        .catch((error) => console.error("Error fetching patients:", error));
    } else {
      fetch("https://helpin.in/kf-form/PatientDetails.php")
        .then((response) => response.json())
        .then((data) => setPatients(data))
        .catch((error) => console.error("Error fetching patients:", error));
    }
  }, [selectedDepartment]); // Runs whenever the department changes

  const handleDepartmentChange = (event) => {
    setSelectedDepartment(event.target.value); // Update the department selection
  };

  // Function to handle checkbox change (mark row as Done and hide it)
  const handleCheckboxChange = (patientId) => {
    // Update the patient's isChecked state locally
    setPatients((prevPatients) =>
      prevPatients.map((patient) =>
        patient.Slno === patientId
          ? { ...patient, isChecked: true, token: "done" } // Mark as checked (done)
          : patient
      )
    );

    // Send update to the PHP backend to mark patient as done
    fetch("https://helpin.in/kf-form/updatePatient.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Slno: patientId,
        token: "done", // Update token field to mark as done
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Patient updated:", data);
      })
      .catch((error) => console.error("Error updating patient:", error));
  };

  return (
    <>
      <br></br>
      <div className="form-container">
        <h2>Patient List</h2>

        <form>
          <label htmlFor="department">Select Department: </label>
          <select
            id="department"
            value={selectedDepartment}
            onChange={handleDepartmentChange}
          >
            <option value="">--Select Department--</option>
            <option value="Gynaecology">Gynaecology</option>
            <option value="ENT">ENT</option>
            <option value="Orthopaedics">Orthopaedics</option>
            <option value="Dermatology">Dermatology</option>
            <option value="Ophthalmology">Ophthalmology</option>
            <option value="General">General/Community medicine</option>
          </select>
        </form>

        <div>
          {patients.length === 0 ? (
            <p>No patients available for this department.</p>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>Sl. No</th>
                  <th>Name</th>
                  <th>Age</th>
                  <th>Weight</th>
                  <th>Height</th>
                  <th>Issue</th>
                  {selectedDepartment === "" && <th>Department</th>} {/* Conditionally render the department column */}
                  {selectedDepartment && <th>Done</th>} {/* Conditionally render "Done" column */}
                  <th>BP</th> {/* Added BP column */}
                  <th>Sugar</th> {/* Added Sugar column */}
                </tr>
              </thead>
              <tbody>
                {patients
                  .filter((patient) =>
                    selectedDepartment ? patient.token !== "done" : true
                  ) // Show all patients if no department is selected
                  .map((patient) => (
                    <tr key={patient.Slno}>
                      <td>{patient.Slno || "NA"}</td>
                      <td>{patient.name || "NA"}</td>
                      <td>{patient.age || "NA"}</td>
                      <td>{patient.weight || "NA"}</td>
                      <td>{patient.height || "NA"}</td>
                      <td>{patient.issue || "NA"}</td>
                      {selectedDepartment === "" && (
                        <td>{patient.dept || "NA"}</td> // Show department only when no department is selected
                      )}
                      {selectedDepartment && (
                        <td>
                          <input
                            type="checkbox"
                            checked={patient.isChecked || false}
                            onChange={() => handleCheckboxChange(patient.Slno)}
                          />
                        </td>
                      )}
                      <td>{patient.BP === "1" ? "Yes" : "No"}</td> {/* Display BP value, check if it's "1" */}
                      <td>{patient.Sugar === "1" ? "Yes" : "No"}</td> {/* Display Sugar value, check if it's "1" */}
                    </tr>
                  ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
};

export default PatientList;
