import React from "react";
import Card from "react-bootstrap/Card";
import { Row, Col } from "react-bootstrap";
import QR from "../img/QRcode.png";

function Donate() {
  //var str = "generous donors by donating more than";
  return (
    <>
      <Card>
        <Card.Header><span style={{ fontWeight: 'bold' }}  >Donate & Help</span></Card.Header>
        {/* <Card.Body>
          <Card.Title>MAHAPOSHAKARU</Card.Title>
          <Card.Text>
            Honorable {str} Rs.10,00,000/- or more will be termed as
            Mahaposhakaru. And their details will be displayed in this website with photo.
          </Card.Text>
        </Card.Body> */}
      </Card>
      {/* <br /> */}
      {/* <Card>
        <Card.Body>
          <Card.Title>POSHAKARU</Card.Title>
          <Card.Text>
            Honorable {str} Rs.1,00,000/- or more will be termed as Poshakaru. And their details will be displayed in this website with photo.
          </Card.Text>
        </Card.Body>
      </Card>
      <br />
      <Card>
        <Card.Body>
          <Card.Title>MahaDaani(Donors)</Card.Title>
          <Card.Text>
            By donating Rs.50,000/- or more will be termed as Maha-Daani. And their details will be displayed in this website with photo.<br />
            <br />
            By donating Rs.2,000/- or more to become honorary member and by donating Rs.1,000/- or more you you will be termed as Daani.
          </Card.Text>
        </Card.Body>
      </Card> */}
      {/* <br /> */}

      <Row>
        <Col md={6}>
          <Card>
            <Card.Header>Use the following details to Donate & Help</Card.Header>
            <Card.Body>
              <Card.Title>UPI QR CODE</Card.Title>
              <Card.Text>
                 <Card.Img variant="top" src={QR}  style={{ width: '18rem' }}/>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          {/* <Card>
            <Card.Body>
              <Card.Title>BANK DETAILS for NEFT</Card.Title>
              <Card.Text>
                Bank name : State Bank of India <br />   
                Beneficiary     :  Keshav Foundation <br />
                A/c No.            : 00000042552316377 <br />
                Branch        : VISVESVARAYA LAYOUT, BANGALORE <br />
                CIF No.        : 91200600128 <br />
                IFS Code     : SBIN0016225 <br />
                MICR Code : 560002204
                
              </Card.Text>
            </Card.Body>
          </Card>
          <br/> */}
          <Card>
        <Card.Body>
          <Card.Title>After Donation Payment</Card.Title>
          <Card.Text>
          Please write to us at <span style={{ color: 'blue', fontWeight: 'bold', textDecoration: 'underline' }}  > donate@helpin.in</span>  or WhatsApp to +91 948 2015 121 with,<br />
           Your Name:<br />Your contact number :<br /> Your email ID :<br />PAN No.(for 80G) :<br />UPI/NEFT details :<br />  Amount :<br />  Payment transaction number :<br />
<br />
<span style={{ color: 'rgba(53,53,53,1))',  fontStyle: 'italic' }}  >Raise by Lifting others...</ span> Write to us for <span style={{ color: 'rgba(53,53,53,1))', fontWeight: 'bold' }}  >80G certificate for your TAX SAVING</span>.
          </Card.Text>
        </Card.Body>
      </Card>
        </Col>
      </Row>


      
    </>
  );
}

export default Donate;
