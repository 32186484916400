import React from "react";
import road from "../img/road.png";
import { Card, Container } from "react-bootstrap";
import ListGroup from "react-bootstrap/ListGroup";
import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom";

function Soon() {
    const containerStyle = {
        backgroundImage: `url(${road})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '100vh',  
        color: 'white',     
      };


  return (
    <>
    <Container fluid style={containerStyle}>
        <Card bg="transparent" text="light">
          <Card.Body>
            <Card.Title className="p-4" style={{ color: "#FFF" }} as="h1">
              <mark> COMING SOON </mark>
            </Card.Title>
          </Card.Body>
        </Card>
      </Container>
      <ListGroup.Item className="transparent-card" as="li">
            <Link to="/patientlist">
                <Button variant="danger"> Patient List </Button>
              </Link>
            </ListGroup.Item>
    </>
  );
}

export default Soon;