import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const RedirectToHome = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to home page if the route is invalid
    navigate('/');
  }, [navigate]);

  return <div>Redirecting...</div>;
}

export default RedirectToHome;
