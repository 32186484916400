import React from "react";
import { Route, Routes } from 'react-router-dom';
import FrontPage from "../pages/froentPage";
import AboutUs from '../pages/aboutUs';
import Donate from "../pages/donate";
import Contact from "../pages/contact";
import Event from "../pages/event";
import Soon from "../componet/Soon";
import RedirectToHome from './RedirectToHome'; 
import RegisterForm from ".././pages/Forms/RegisterForm";
import PatientList from ".././pages/Forms/PatientList";


const RoutesComponent = () => {
  return (
    <Routes>
      <Route path="/" element={<FrontPage />} />
      <Route path="/AboutUs" element={<AboutUs />} />
      <Route path="/Donate" element={<Donate />} />
      <Route path="/Contact" element={<Contact />} />
      <Route path="/Event" element={<Event />} />
      <Route path="/comingSoon" element={<Soon />} />

      {/* Catch-all route for invalid paths */}
      <Route path="*" element={<RedirectToHome />} />
      <Route path="/register" element={<RegisterForm />} />
      <Route path="/patientList" element={<PatientList />} />
    </Routes>
  );
}

export default RoutesComponent;
