import React from "react";
import { Card, Button, Container, Row, Col } from "react-bootstrap";
import ankolacamp from "../img/ankolacamp.png";
import ankolacampeng from "../img/ankolacampEng.png";
import "../App.css";
import { Link } from "react-router-dom";


export default function EventPage() {
  return (
    <Container fluid style={{ backgroundColor: "#f8f9fa", padding: "30px 0" }}>
      {/* Heading Section */}
      <Row className="mb-4">
        <Col className="text-center">
          <h1
            style={{
              color: "#333",
              textShadow: "2px 2px 4px rgba(0, 0, 0, 0.6)",
              fontSize: "2.5rem",
            }}
          >
            Upcoming Events
          </h1>
        </Col>
      </Row>

      {/* Event Image Section 1 */}
      <Row className="mb-4">
        <Col>
          <Card
            className="text-center"
            border="0"
            style={{ backgroundColor: "#f1f1f1" }}
          >
            <img
              src={ankolacamp}
              className="rounded"
              alt="Camp Image"
              style={{
                width: "100%",
                height: "auto",
                borderRadius: "8px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              }}
            />
          </Card>
        </Col>
      </Row>

      {/* Event Image Section 2 */}
      <Row className="mb-4">
        <Col>
          <Card
            className="text-center"
            border="0"
            style={{ backgroundColor: "#f1f1f1" }}
          >
            <img
              src={ankolacampeng}
              className="rounded"
              alt="Camp Image"
              style={{
                width: "100%",
                height: "auto",
                borderRadius: "8px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              }}
            />
          </Card>
        </Col>
      </Row>

      {/* Event Description Section */}
      <Row className="mb-4">
        <Col lg={9} className="mx-auto">
          <Card>
            <Card.Body>
              <Card.Title style={{ fontSize: "1.75rem", color: "#343a40" }}>
                Health Camps
              </Card.Title>
              <Card.Text style={{ fontSize: "1.1rem", lineHeight: "1.6" }}>
                Starting from 2024, we have been conducting Health Camps in
                collaboration with many socially responsible associations in
                Karnataka, specifically in Bengaluru rural areas. Recently, we
                decided to focus on conducting Health Camps only where there is
                a greater need.
                <br />
                <br />
                In Uttara Kannada district, there isn't a single
                multi-speciality hospital in one of the largest districts of
                Karnataka, even after 78 years of independence. People in this
                district are forced to depend on hospitals in Manipal, Udupi,
                Mangalore, or Panjim, Goa.
              </Card.Text>
              <div className="text-center">
              <Link to="/register">
                  <Button variant="primary" size="lg">
                    Registrations
                  </Button>
                </Link>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
