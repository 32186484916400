// src/App.js

import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import Header from "./componet/header";
import Footer from './componet/footer';
import { Container } from 'react-bootstrap';
import { BrowserRouter as Router } from 'react-router-dom';
import RoutesComponent from './router/Routes';  
import 'bootstrap/dist/css/bootstrap.min.css';


function App() {
  return (
    <Router>
      <Header />
      <Container fluid style={{ marginTop: '96px', backgroundColor: 'gray' }}>
        <RoutesComponent /> 
      </Container>
      <Footer />
    </Router>
  );
}

export default App;
