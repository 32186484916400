import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import logo from "../img/Logo.png";
import "./Header.css"; // Import the custom CSS file

function Header() {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const navRef = useRef(null);

  // Function to toggle the navbar collapse
  const handleNavToggle = () => {
    setIsNavOpen(!isNavOpen);
  };

  // Function to close navbar after selecting a link
  const handleLinkClick = () => {
    setIsNavOpen(false); // Close the navbar when any link is clicked
  };

  // Close the navbar if clicked outside
  const handleClickOutside = (e) => {
    if (navRef.current && !navRef.current.contains(e.target)) {
      setIsNavOpen(false); // Close the navbar if click is outside
    }
  };

  // Set up event listener for clicks outside the menu
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup event listener on unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <header className="custom-navbar">
      <div className="navbar-container">
        {/* Navbar.Brand: Make the logo image responsive */}
        <div className="navbar-brand">
          <Link to="/" className="brand-link">
            <img
              src={logo}
              alt="Logo"
              className="logo-img"
            />
            <span className="brand-name">Keshav Foundation</span>
          </Link>
        </div>

        {/* Navbar Toggle for mobile */}
        <button className="navbar-toggle" onClick={handleNavToggle}>
          <span className="navbar-toggle-icon">☰</span> {/* Example icon */}
        </button>

        {/* Navbar Links */}
        <div ref={navRef} className={`navbar-links ${isNavOpen ? "open" : ""}`}>
          <Link to="/AboutUs" className="navbar-link" onClick={handleLinkClick}>
            About Us
          </Link>
          <Link to="/Contact" className="navbar-link" onClick={handleLinkClick}>
            Contact Us
          </Link>
          <Link to="/Donate" className="navbar-link" onClick={handleLinkClick}>
            <Button className="donate-btn">Donate</Button>
          </Link>
        </div>
      </div>
    </header>
  );
}

export default Header;
